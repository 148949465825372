<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="banks"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">

        <v-row>
          <v-col cols="12" sm="12" md="5">
          <v-form ref="form2" v-model="valid">
            <v-autocomplete
              v-model="id_customer"
              :items="customers"
              :rules="editedItem.id_customerRules"
              item-text="name"
              item-value="id"
              label="Escoge un Cliente"
              @change="getBanks"
              clearable
              rounded
              solo
            ></v-autocomplete>
          </v-form>
          </v-col>
        </v-row>
        
        <v-btn
            @click="selectOption"
            v-if="!id_customer"
            color="primary"
            dark
            class="mb-2"
        >Nuevo Registro</v-btn>
          
          <v-dialog v-model="dialog" max-width="600px" v-if="id_customer">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark class="mb-2" v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.certificate_number"
                          label="Número de Certificado"
                          :rules="editedItem.certificate_numberRules"
                          type="number"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.identity"
                          v-mask="'####-####-#####'"
                          label="ID"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="editedItem.nameRules"
                          label="Nombre del asegurado"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.date"
                              :rules="editedItem.dateRules"
                              label="Fecha de alta"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.date"
                            no-title
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500">
            <v-card>
              <v-card-title>
                <span>Desea eliminar a {{editedItem.name}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dependents -->

          <v-dialog 
          v-model="dependentList" 
          max-width="700px"
          >
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              
              <v-card-title>
                <v-col cols="12" sm="12" md="4">
                <span class="headline">Dependientes</span>
                </v-col>
                  
                <v-spacer></v-spacer>
                <div>

              <v-btn
                style="right:10px; top:10px;"
                icon
                color="error"
                fab
                absolute
                @click="dependentList = false"
              >
                <v-icon dark>fa-times</v-icon>
              </v-btn>

                </div>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    :headers="headers_dependents"
                    :items="dependents"
                    sort-by="date"
                    sort-desc
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                  >
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>

                        <v-dialog v-model="dependentDialog" max-width="600px">
                          <template v-slot:activator="{ on }">
                              <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle2 }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-form ref="form3" v-model="valid">
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem2.name"
                                        label="Nombre"
                                        :rules="editedItem2.nameRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-text-field
                                        v-model="editedItem2.kinship"
                                        label="Parentezco"
                                        :rules="editedItem2.kinshipRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                      <v-menu
                                      ref="menu"
                                      v-model="menu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          v-model="editedItem2.birthday"
                                          label="Fecha de nacimiento"
                                          prepend-icon="mdi-calendar"
                                          :rules="editedItem2.birthdayRules"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="editedItem2.birthday"
                                        :active-picker.sync="activePicker"
                                        min="1950-01-01"
                                        @change="saveDate"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDependent">Cancelar</v-btn>
                              <v-btn color="blue darken-1" text @click="saveDependent">Guardar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-dialog v-model="dependentDialogDelete" max-width="450">
                        <v-card>
                          <v-card-title>
                            <span>¿Desea eliminar a {{editedItem2.name}}?</span>
                            <v-spacer></v-spacer>
                          </v-card-title>

                          <v-card-actions>
                            <v-btn color="green darken-1" text @click="closeDependent">Cancelar</v-btn>
                            <v-btn color="red darken-1" text @click="delete2">Aceptar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon small class="mr-2" @click="editItemDependent(item)">fas fa-edit</v-icon>
                      <v-icon small @click="deleteItemDependent(item)">fas fa-trash-alt</v-icon>
                    </template>

                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue darken-3" v-bind="attrs" v-on="on" class="mr-3" @click="dependent(item)">fas fa-children</v-icon>
          </template>
          <span>Dependientes</span>
        </v-tooltip>
        <v-icon color="success" class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
      <template v-slot:[`item.path`]="{ item }">
          <v-icon 
          v-if="item.path"
          color="purple darken-3"
          @click="seeDocument(item)"
          >fas fa-file-pdf</v-icon>
        </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  name: "policyholders",
  data: () => ({
    dialog: false,
    banks: [],
    customers: [],
    dependents: [],
    dialogDelete: false,
    dependentDialogDelete: false,
    dependentDialog: false,
    dependentList: false,
    search: "",
    loading: false,
    titulo: "Asegurados",
    headers: [
      { text: "#Certificado", align: "left", sortable: false, value: "certificate_number" },
      { text: "Identidad", align: "left", sortable: false, value: "identity" },
      { text: "Nombre", value: "name" },
      { text: "Fecha de alta", value: "date" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    headers_dependents: [
      { text: "Nombre", align: "left", value: "name" },
      { text: "Parentezco", align: "left", value: "kinship" },
      { text: "Fecha de nacimiento", align: "left", value: "birthday" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    id_customer: null,
    policyholder: null,
    activePicker: null,
    menu: false,
    menu2: false,
    
    editedIndex: -1,

    valid: true,
    editedItem: {
      id: "",
      name: "",
      certificate_number: "",
      identity: "",
      date: "",
      id_customerRules: [v => !!v || "Escoge un Cliente"],
      nameRules: [v => !!v || "Escribe el nombre del asegurado"],
      certificate_numberRules: [v => !!v || "Escribe el número de certificado"],
      dateRules: [v => !!v || "Escribe la fecha de alta"],
      identityRules: [
                      v => !!v || "Escribe la identidad",
                      v => /^\d{4}-\d{4}-\d{5}$/.test(v) || "Escribe correctamente la Identidad"
                     ]
    },
    defaultItem: {
      name: "",
      certificate_number: "",
      identity: "",
      date: "",
      id_customerRules: [v => !!v || "Escoge un Cliente"],
      nameRules: [v => !!v || "Escribe el nombre del asegurado"],
      certificate_numberRules: [v => !!v || "Escribe el número de certificado"],
      dateRules: [v => !!v || "Escribe la fecha de alta"],
      identityRules: [
                      v => !!v || "Escribe la identidad",
                      v => /^\d{4}-\d{4}-\d{5}$/.test(v) || "Escribe correctamente la Identidad"
                     ]
    },

    editedItem2: {
      id: "",
      name: "",
      kinship: "",
      birthday: "",
      nameRules: [v => !!v || "Escribe el nombre"],
      kinshipRules: [v => !!v || "Escribe el parentezco"],
      birthdayRules: [v => !!v || "Escribe la fecha de nacimiento"],
    },

    defaultItem2: {
      name: "",
      kinship: "",
      birthday: "",
      nameRules: [v => !!v || "Escribe el nombre"],
      kinshipRules: [v => !!v || "Escribe el parentezco"],
      birthdayRules: [v => !!v || "Escribe la fecha de nacimiento"],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    formTitle2() {
      return this.editedIndex2 === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    path_label(){
      return this.editedItem.filename;
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),
    // Metodos base de datos
    async getPolicies(){
      
      await axios.get(this.db + 'list/customers',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getPolicies(); })
        });
    },

    async getBanks(){
      console.log(this.id_customer);
      this.banks = [];
      if (!this.id_customer > 0) {
        return;
      }
      
      await axios.get(this.db + 'policyholders/' + this.id_customer,
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    addProcess(payload){
      // console.log("add holder",payload);
      axios.post(this.db + 'policyholder/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getBanks();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addProcess(payload); })
      });

    },

    updateProcess(payload){

      axios.post(this.db + 'policyholder/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getBanks();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.updateProcess(payload); })
      });

    },

    deleteProcess(id){

      axios.post(this.db + 'policyholder/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.banks = this.banks.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteProcess(id); })
      });

    },

    // DEPENDENTS

    async getDependents(){

      await axios.get(this.db + 'dependents/' + this.policyholder,
        {
          headers: this.headers_db
        }).then(response => {
          this.dependents = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.dependents = [];
          this.validateSession(error).then((resp) => { if(resp) this.getDependents(); })
        });
    },

    addDependent(payload){
      console.log(payload);
      axios.post(this.db + 'dependent/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getDependents();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addDependent(payload); })
      });

    },

    updateDependent(payload){

      axios.post(this.db + 'dependent/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getCustomers();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.updateDependent(payload); })
      });

    },

    deleteDependent(id){

      axios.post(this.db + 'dependent/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.dependents = this.dependents.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.deleteDependent(id); })
      });

    },

    // FIN Metodos base de datos


    // Metodos Generales
    selectOption() {
      if (!this.id_customer) {
        this.$refs.form2.validate();
      }
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    editItemDependent(item) {
      this.editedIndex2 = this.dependents.indexOf(item);
      this.dependentDialog = true;
      this.editedItem2 = Object.assign({}, item);
    },

    deleteItemDependent(item) {
      console.log(item.id);

      this.editedItem2 = Object.assign({}, item);
      this.dependentDialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.$refs.form.validate() && this.$refs.form2.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.banks[this.editedIndex], this.editedItem);
          this.updateProcess(this.editedItem);
        } else {
          this.banks.push(this.editedItem);
          this.editedItem.id_customer = this.id_customer;
          this.addProcess(this.editedItem);
        }
        this.close();
      }
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    },

    // Dependientes
    dependent(item) {
      console.log(item.id);
      this.policyholder = item.id;
      this.getDependents();
      this.dependentList = true;
    },

    closeDependent() {
      
      this.dependentDialog = false;
      this.dependentDialogDelete = false;
      setTimeout(() => {
        this.editedItem2 = Object.assign({}, this.defaultItem2);
        this.editedIndex2 = -1;
      }, 300);
    },

    saveDependent() {
      if (this.$refs.form3.validate()) {
        if (this.editedIndex2 > -1) {
          Object.assign(this.dependents[this.editedIndex2], this.editedItem2);
          this.updateDependent(this.editedItem2);
        } else {
          this.dependents.push(this.editedItem2);
          this.editedItem2.policyholder = this.policyholder;
          this.addDependent(this.editedItem2);
        }
        this.closeDependent();
      }
    },

    delete2() {
      this.deleteDependent(this.editedItem2.id);
      this.closeDependent();
    },
  },
  created() {
    this.getPolicies();
  }
};
</script>

